.headerGrid {
    background-color: black;
    text-align: center;
    min-height: 100px;
    max-height: 200px;

    .image {
        height: 100%;
        max-height: 35px;
    }

    .langLog {
        justify-content: flex-end;
    }
}
