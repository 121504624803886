.loginPage {
    .title {
        padding-top: var(--titleSpacing);
        place-content: center;
        text-align: center;
    }

    .form {
        place-content: center;
        margin: 40px 0 80px 0;
        text-align: -webkit-center;
        text-align: -moz-center;
        text-align: -o-center;
        @media screen and (max-width: 600px) {
            margin: 20px 0;
        }
    }

    .description {
        max-width: 500px;
        padding: 20px 0;
        .title {
        }

        .text {
        }
    }

    .downloadIcons {
        img {
            width: 150px;
        }
    }

    @media screen and (max-width: 600px) {
        .form {
            margin: 20px 0;
        }
        .description {
            padding: 10px 0;
        }
    }
}
