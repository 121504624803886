.sideList {

    &.MuiList-padding {
        padding-top: 0;
        // border: solid 1px var(--primaryMain)
    }
}

.activeSideList {
    color: green;
}

.inactiveSideList {
    color: red;
}

.disabledSideList {
    color: black;
}
