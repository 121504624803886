.deviceDetails {
    text-align: left;

    .title {
        padding: var(--titleSpacing) 0;
    }

    &.buttonGroup {
        text-align: -webkit-left;
        text-align: -moz-left;
        text-align: -o-left;
    }
}
