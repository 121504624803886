.footerGeneral {
    margin-top: 1rem;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(239, 239, 239,0);
    text-align: center;
    position: absolute;
    .grid {
        height: 75px;
    }
}
