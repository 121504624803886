.listTableHead {
    background-color: var(--primaryMain);
    color: var(--secondaryMain);

    .MuiCheckbox-root {
        color: var(--secondaryMain);
    }

    .visuallyHidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1;
        margin: -1;
        overflow: hidden;
        padding: 0;
        position: absolute;
        top: 20;
        width: 1;
    }

    .MuiTableSortLabel-icon {
        opacity: 0.2;
        color: var(--secondaryMain)!important;
        &:hover {
            opacity: 0.9;
        }
    }
}
