.helpPage {
    padding: 0 var(--outerSpacing);
    .title {
        text-align: center;
        padding: var(--titleSpacing) 0;
    }

    .body {
        text-align: justify;
    }
}
