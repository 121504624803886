
@font-face {
  font-family: 'Nationale-DemiBold';
  font-weight: 600;
  src: local('Nationale'), url(./Fonts/Nationale-DemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Nationale';
  src: local('Nationale'), url(./Fonts/Nationale-Regular.otf) format('opentype');
}


body {
  margin: 0;
  font-family: Nationale, Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
  padding-bottom: 6rem;
  background-image: url("./images/Wolke01.png");
  background-size: cover;
}

code {
  font-family: 'Nationale';
}

b{
  font-family: "Nationale-DemiBold";
}