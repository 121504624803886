

:root {

--smQuery: 600px;
--mdQuery: 960px;
--lgQuery: 1280px;
--xlQuery: 1920px;

--outerSpacing: 40px;
--titleSpacing: 40px;

@media screen and (max-width: 960px) {
    --outerSpacing: 20px;
}

}