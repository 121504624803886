.scheduler2 {
    max-width: 1500px;
    text-align-last: center;

    .schedulerButtons {
        padding: 40px 0;
    }
}

.scheduler2Modal {
    width: 400px;
    top: 40vh;
    border: 2px solid #000;
    padding: 25px 20px;
    margin: auto;
    position: relative;
    text-align: center;

    .formTimeFrom {
    }

    .formTimeTo {
    }

    .formIntense {
    }
}
