.tableList {
    .root {
        width: 100%;
    }
    .paper {
        width: 100%;
        margin-bottom: 10px;
    }
    .table {
        min-width: 750;
    }

    .tableContainer {
        text-align-last: center;
    }

    .listButton {
        margin: 0 10px;
    }
}
