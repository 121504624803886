.contactPage {
    text-align: center;
    .title {
        padding: var(--titleSpacing) var(--outerSpacing);
    }
    .body {
        text-align: justify;
    }

    .textContact {
        background-color: rgba(255,255,255, 0.5);
    }
}
