$primary: var(--primaryMain);
$secondary: var(--secondaryMain);

button {
    &.customButton {
        &.Mui-disabled {
            opacity: 0.5;
        }
        &.primary {
            &.MuiButton-root {
                background-color: $primary;
                color: $secondary;
                a {
                    color: $secondary;
                    text-decoration: none;
                }
                &:hover {
                    background-color: $secondary;
                    color: $primary;
                    a {
                        color: $primary;
                    }
                }
            }
        }
        &.secondary {
            &.MuiButton-root {
                background-color: $secondary;
                color: $primary;
                a {
                    color: $primary;
                    text-decoration: none;
                }
                &:hover {
                    background-color: $primary;
                    color: $secondary;
                    a {
                        color: $secondary;
                    }
                }
            }
        }
    }
}
